import React from "react";

const Test = () => {
  return (
    <div>
      <h1>Test Page</h1>
    </div>
  );
};

export default Test;
